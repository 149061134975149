"use client";
import Autoplay from "embla-carousel-autoplay";
import { redirect, useSearchParams } from "next/navigation";
import { useAuth } from "@aether/shared/context/auth";
import { Suspense, useEffect, useState } from "react";
import PreSignUpForm from "@/components/organisms/presignup-form";

import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { MousePointer2 } from "lucide-react";
import { cn } from "@/lib/utils";
import Image from "next/image";
import HomePageSearch from "@/components/homepage-search";

const person = ["Tita", "Kuya", "Jowa", "Tito", "Ate", "Lola"];

function CatchPhrase() {

  const [count, setCount] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setCount(prevCount => prevCount + 1)
    }, 3000)

    return () => clearInterval(timer)
  }, [])


  return <p className="text-center bg-white w-fit mx-auto p-2 rounded-lg">
    Photos so good, even your <strong>{person[count % person.length]}</strong> will approve!
  </p>
}

function SearchSection() {
  const params = useSearchParams();
  const locationId = params.get("location");
  const from = params.get("from");
  const to = params.get("to");

  const { user, isLoading } = useAuth();
  const [current, setCurrent] = useState(0);
  const [api, setApi] = useState<CarouselApi>();


  useEffect(() => {
    if (user && !isLoading) {
      return redirect("/search");
    }
  }, [user, isLoading]);

  useEffect(() => {
    if (!api) {
      return;
    }
    setCurrent(api.selectedScrollSnap());

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap());
    });
  }, [api]);

  return (
    <div className="grid gap-y-10 p-2">
      <section className="relative mx-auto h-[500px] max-w-[500px] w-full rounded-lg overflow-hidden group">
        <Carousel
          setApi={setApi}
          opts={{ loop: true, watchDrag: false }}
          plugins={[
            Autoplay({
              delay: 4000,
              stopOnMouseEnter: true,
              stopOnInteraction: false,
            }),
          ]}
        >
          <CarouselContent>
            <CarouselItem className="relative flex items-center h-[500px] w-full rounded-lg aspect-square">
              <Image
                width={1000}
                height={1000}
                src="/images/landing-page/sample-photo-2.jpg"
                alt="sample photo 1"
                className="object-cover"
              />
            </CarouselItem>
            <CarouselItem className="relative flex items-center h-[500px] w-full  rounded-lg aspect-square">
              <Image
                width={1000}
                height={1000}
                src="/images/landing-page/sample-photo-3.jpg"
                alt="sample photo 2"
                className="object-cover"
              />
            </CarouselItem>
            <CarouselItem className="relative flex items-center h-[500px] w-full  rounded-lg aspect-square">
              <Image
                width={1000}
                height={1000}
                src="/images/landing-page/sample-photo-4.jpg"
                alt="sample photo 3"
                className="object-cover"
              />
            </CarouselItem>
          </CarouselContent>
          <div className="group-hover:visible group-hover:opacity-100 invisible opacity-0 transition-all duration-300 ease-in-out">
            <CarouselPrevious />
            <CarouselNext />
          </div>
        </Carousel>
        <span className="absolute flex items-center bottom-3 right-3 bg-white rounded-full py-2 px-3">
          <Avatar className="w-4 h-4">
            <AvatarImage
              src="/images/landing-page/nguyen-hung.jpeg"
              alt="@shadcn"
            />
            <AvatarFallback>NH</AvatarFallback>
          </Avatar>
          <p className="text-xs pl-1">Nguyen Hung</p>
        </span>

        <span className="absolute bottom-3 left-3 text-xs bg-zinc-950 text-white rounded-full py-1.5 px-2.5">
          <span className="text-yellow-400">{current + 1}</span> of 3
        </span>
      </section>

      <div className="relative w-fit mx-auto">
        <span
          className={cn(
            "absolute -top-1 -right-5",
            locationId || from ? "hidden" : "",
          )}
        >
          <MousePointer2 className="-rotate-[70deg]" />
          <span className="absolute -top-2 -right-[90px]">
            <p className="border-2 border-zinc-950 rounded-lg px-1 text-sm">
              Search Here
            </p>
          </span>
        </span>
        <HomePageSearch />
      </div>
      <div className="grid gap-4 mt-5 mb-1">
        <h1 className="text-4xl md:text-6xl font-bold leading-snug text-aether-blue text-center">
          Discover, Capture, Inspire
          <br />
          with AetherLenz
        </h1>
        <h3 className="md:text-lg leading-loose text-neutral-600 text-center max-w-xl mx-auto">
          Look for photography captains, ask for inspiring shots, and find your
          photos here in seconds.
        </h3>
      </div>
      <div className="rounded-lg bg-amber-400 w-fit p-6 mx-auto">

        <h4 className="text-3xl font-semibold mb-6 text-center">
          How it works
        </h4>
        <div className="pl-7 max-w-xl text-justify">
          <div className="border-l border-dashed border-aether-blue relative pl-10 pr-4 pt-1 pb-10 border-spacing-24 ">
            <div className="aspect-ratio h-14 w-14 text-2xl text-white bg-aether-blue rounded-full flex items-center justify-center text-center absolute -left-7 top-0">
              1
            </div>
            <h4 className="text-xl text-left">Sign Up and Upload a Selfie</h4>
            <p className="font-light text-sm">
              Create an account on AetherLenz before or during your trip. Upload
              a clear selfie so it&apos;s easy to find your photos later.
            </p>
          </div>
          <div className="border-l border-dashed border-aether-blue relative pl-10 pr-3 lg:pr-10 pt-1 pb-10 border-spacing-24 ">
            <div className="aspect-ratio h-14 w-14 text-2xl text-white bg-aether-blue rounded-full flex items-center justify-center text-center absolute -left-7 top-0">
              2
            </div>
            <h4 className="text-xl text-left">Get Photographed at Tourist Spots</h4>
            <p className="font-light text-sm">
              When you visit your chosen destination, our photographers will
              check your registration and be around to capture your
              moments—whether it&apos;s enjoying the view or doing fun
              activities. Just let them know.
            </p>
          </div>
          <div className="border-l border-dashed border-aether-blue relative pl-10 pr-3 lg:pr-10 pt-1 pb-10 border-spacing-24 ">
            <div className="aspect-ratio h-14 w-14 text-2xl text-white bg-aether-blue rounded-full flex items-center justify-center text-center absolute -left-7 top-0">
              3
            </div>
            <h4 className="text-xl text-left">Find and Buy Your Photos</h4>
            <p className="font-light text-sm">
              After your trip, find your photos by date, and location. Browse
              the photos, choose your favorites, and purchase them.
            </p>
          </div>
          <div className="relative pl-10 pr-3 lg:pr-10 pt-1 pb-10">
            <div className="aspect-ratio h-14 w-14 text-2xl text-white bg-aether-blue rounded-full flex items-center justify-center text-center absolute -left-7 top-0">
              4
            </div>
            <h4 className="text-xl text-left">Download and Share!</h4>
            <p className="font-light text-sm">
              Once you buy your photos, you can download them instantly. Share
              them on social media or keep them as souvenirs of your trip!
            </p>
          </div>
        </div>
        <CatchPhrase />
      </div>
      <div className="grid gap-y-10 pt-4">
        <div className="flex flex-col items-center ">
          <h1 className="text-3xl font-semibold leading-snug text-center">
            Capture More, Earn More! Join our network today!
          </h1>
          <h3 className="max-w-lg font-light text-neutral-800 mt-2.5 px-2 text-center">
            We help attractions/organizers and skilled photographers sell their
            photos to visitors with the help of Artificial Intelligence (AI).
          </h3>
        </div>
        <PreSignUpForm />
      </div>
    </div>
  );
}

export default function Home() {
  return (
    <Suspense>
      <SearchSection />
    </Suspense>
  );
}

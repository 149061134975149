">puse client";

import { Button } from "@/components/ui/button";
import Image from "next/image";
import { signInWithGoogle } from "@aether/shared/lib/firebase/firebase.client";
import { useRouter } from "next/navigation";

export const LoginRedirects = () => {
  return (
    <section>
      <GoogleLogin />
      <small className="mt-2 text-center">
        Don&lsquo;t have an account? Sign up now!
      </small>
    </section>
  );
};

export const GoogleLogin = ({ url }: { url?: string }) => {
  const router = useRouter();
  const onGoogleProviderSignIn = async () => {
    try {
      await signInWithGoogle();
      router.push(url ?? "/search");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Button
      className="relative w-full h-full flex gap-2"
      type="button"
      variant="secondary"
      onClick={() => void onGoogleProviderSignIn()}
    >
      <Image
        alt="google login"
        className="h-5 w-5"
        height={48}
        src="/icons/google.svg"
        width={48}
      />
      <span>Login with Google</span>
    </Button>
  );
};

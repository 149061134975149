">puse client";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { presignupSchema } from "@aether/shared/models/presignup";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { presignup } from "@aether/shared/services/presignup";
import { toast } from "sonner";
import { Checkbox } from "@/components/ui/checkbox";
import Link from "next/link";
import { cn } from "@/lib/utils";
import { Textarea } from "../ui/textarea";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { Role } from "@aether/db";

export default function PreSignUpForm() {
  const form = useForm<z.infer<typeof presignupSchema>>({
    resolver: zodResolver(presignupSchema),
    defaultValues: {
      fullName: "",
      email: "",
      organization: "",
      phoneNumber: "",
      province: "",
      consent: false,
      role: "photographer",
    },
  });

  async function onSubmit(data: z.infer<typeof presignupSchema>) {
    const presignupPhotographer = await presignup(data);
    if (typeof presignupPhotographer === "string") {
      toast.error(presignupPhotographer);
    } else {
      form.reset();
      toast.success("Thank you for your signing up! Signup success! 🤞");
    }
  }

  const role = form.watch("role");

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="max-w-xl border border-zinc-500 mx-auto w-full"
      >
        <h1 className="text-center w-full text p-2 border-b border-zinc-500 bg-zinc-100">
          Network Signup Form
        </h1>
        <div className="p-4">
          <span>
            <p className="font-semibold">
              Join the Network
            </p>
            <p className="font-light text-sm">
              Be one of the firsts to enjoy new opportunities. Your talent deserves more recognition!
            </p>
          </span>
          <RadioGroup
            onValueChange={(e) => {
              form.reset()
              form.setValue("role", e as Role)
            }}
            defaultValue={role}
            className="grid pt-4"
          >
            <div className="grid border rounded-lg text-sm">
              <span className="flex gap-2 items-center w-full border-b py-4 px-3">
                <RadioGroupItem value="photographer" />
                <p>
                  Photographer
                </p>
              </span>
              <div className={cn("grid grid-cols-2 gap-2.5 px-3 py-4 bg-zinc-100", role === "photographer" ? "" : "hidden")}>
                <FormField
                  control={form.control}
                  name="fullName"
                  render={({ field }) => (
                    <FormItem className="col-span-full lg:col-span-1">
                      <FormLabel className="flex items-end gap-1 font-light">
                        <p>Name</p>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} className="h-12 bg-white" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="province"
                  render={({ field }) => (
                    <FormItem className="col-span-full lg:col-span-1">
                      <FormLabel className="flex items-end gap-1 font-light">
                        <p>Province</p>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} className="h-12 bg-white" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="col-span-full lg:col-span-1">
                      <FormLabel className="flex items-end gap-1 font-light">
                        <p>Email Address</p>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} className="h-12 bg-white" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="phoneNumber"
                  render={({ field }) => (
                    <FormItem className="col-span-full lg:col-span-1">
                      <FormLabel className="flex items-end gap-1 font-light">
                        <p>Contact Number</p>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} className="h-12 bg-white" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="organization"
                  render={({ field }) => (
                    <FormItem className="col-span-full">
                      <FormLabel className="flex items-end gap-1 font-light">
                        <p>Affiliation/Organization (if any)</p>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} className="h-12 bg-white" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="consent"
                  render={({ field }) => (
                    <FormItem className="col-span-full ">
                      <FormControl>
                        <div className="flex items-center leading-none">
                          <Checkbox
                            className="h-3.5 w-3.5"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                          <span className="flex gap-1 items-center pl-1 font-light text-xs">
                            I agree to
                            <Link
                              href="/data-privacy-content"
                              rel="noopener noreferrer"
                              target="_blank"
                              className="underline"
                            >
                              AetherLenz Data Privacy Form
                            </Link>
                          </span>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="grid border rounded-lg text-sm">
              <span className="flex gap-2 items-center w-full border-b py-4 px-3">
                <RadioGroupItem value="organizer" />
                <p>
                  Organizer
                </p>
              </span>
              <div className={cn("grid grid-cols-2 gap-2.5 px-3 py-4 bg-zinc-100", role === "organizer" ? "" : "hidden")}>
                <FormField
                  control={form.control}
                  name="fullName"
                  render={({ field }) => (
                    <FormItem className="col-span-full lg:col-span-1">
                      <FormLabel className="flex items-end gap-1 font-light">
                        <p>Name</p>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} className="h-12 bg-white" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="province"
                  render={({ field }) => (
                    <FormItem className="col-span-full lg:col-span-1">
                      <FormLabel className="flex items-end gap-1 font-light">
                        <p>Province</p>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} className="h-12 bg-white" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="col-span-full lg:col-span-1">
                      <FormLabel className="flex items-end gap-1 font-light">
                        <p>Email Address</p>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} className="h-12 bg-white" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="phoneNumber"
                  render={({ field }) => (
                    <FormItem className="col-span-full lg:col-span-1">
                      <FormLabel className="flex items-end gap-1 font-light">
                        <p>Contact Number</p>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} className="h-12 bg-white" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="organization"
                  render={({ field }) => (
                    <FormItem className="col-span-full">
                      <FormLabel className="flex items-end gap-1 font-light">
                        <p>Affiliation/Organization (if any)</p>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} className="h-12 bg-white" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="consent"
                  render={({ field }) => (
                    <FormItem className="col-span-full">
                      <FormControl>
                        <div className="flex items-center leading-none">
                          <Checkbox
                            className="h-3.5 w-3.5"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                          <span className="flex gap-1 items-center pl-1 font-light text-xs">
                            I agree to
                            <Link
                              href="/data-privacy-content"
                              rel="noopener noreferrer"
                              target="_blank"
                              className="underline"
                            >
                              AetherLenz Data Privacy Form
                            </Link>
                          </span>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="message"
                  render={({ field }) => (
                    <FormItem className="col-span-full">
                      <FormLabel className="flex items-end gap-1 font-light">
                        <p>Leave us a message</p>
                      </FormLabel>
                      <FormControl>
                        <Textarea {...field} className="bg-white" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </RadioGroup>
          <div className="w-full mt-2">
            <Button className="w-full h-12" disabled={form.formState.isSubmitting}>
              {form.formState.isSubmitting ? (
                <div className="ml-2 border-t-2 border-r-2 h-4 w-4 rounded-full animate-spin" />
              ) : (
                <p>Subscribe</p>
              )}
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
}
